// visualizer.ts

declare global {
  interface Window {
    webkitAudioContext?: typeof AudioContext;
  }
}

export type VisualizerConfig = {
  audioElement: HTMLAudioElement;
  canvasElement: HTMLCanvasElement;
  style?: 'line' | 'radial';
  barWidth?: number;
  barHeight?: number;
  barSpacing?: number;
  barColor?: string;
  shadowBlur?: number;
  shadowColor?: string;
  onComplete?: () => void;
};

export type VisualizerMethodNames = 'renderRadial' | 'renderLine';

const TYPE: {
  [key: string]: VisualizerMethodNames;
} = {
  radial: 'renderRadial',
  line: 'renderLine',
};

export class Visualizer {
  // Properties
  isPlaying = false;
  audio: HTMLAudioElement;
  canvas: HTMLCanvasElement;
  canvasCtx: CanvasRenderingContext2D;
  ctx: AudioContext;
  analyser: AnalyserNode;
  sourceNode: MediaElementAudioSourceNode;
  frequencyData: Uint8Array;
  style: string;
  barWidth: number;
  barHeight: number;
  barSpacing: number;
  barColor: string;
  shadowBlur: number;
  shadowColor: string;
  gradient: CanvasGradient;
  animationFrameId = 0;
  onComplete?: () => void;

  // Constructor
  constructor(cfg: VisualizerConfig) {
    this.audio = cfg.audioElement;
    this.canvas = cfg.canvasElement;
    const canvasCtx = this.canvas.getContext('2d');
    if (!canvasCtx) {
      throw new Error('Failed to get 2D context from canvas');
    }
    this.canvasCtx = canvasCtx;
    this.ctx = this.createAudioContext();
    this.analyser = this.ctx.createAnalyser();
    this.sourceNode = this.ctx.createMediaElementSource(this.audio);
    this.frequencyData = new Uint8Array();
    this.style = cfg.style || 'radial';
    this.barWidth = cfg.barWidth || 2;
    this.barHeight = cfg.barHeight || 2;
    this.barSpacing = cfg.barSpacing || 2;
    this.barColor = cfg.barColor || '#ffffff';
    this.shadowBlur = cfg.shadowBlur || 10;
    this.shadowColor = cfg.shadowColor || '#ffffff';
    this.gradient = this.canvasCtx.createLinearGradient(0, 0, 0, 300);
    this.onComplete = cfg.onComplete;

    // Initialize the visualizer
    this.init();
  }

  // Create AudioContext
  private createAudioContext(): AudioContext {
    const AudioContextClass = window.AudioContext || window.webkitAudioContext;
    if (!AudioContextClass) {
      throw new Error('Web Audio API is not supported in this browser');
    }
    return new AudioContextClass();
  }

  // Initialize the visualizer
  private init(): void {
    this.setupAnalyser();
    this.setupAudioNodes();
    this.setupCanvasStyles();
    this.bindEvents();
  }

  // Setup the analyser
  private setupAnalyser(): void {
    this.analyser.smoothingTimeConstant = 0.6;
    this.analyser.fftSize = 2048;
    this.frequencyData = new Uint8Array(this.analyser.frequencyBinCount);
    this.analyser.minDecibels = -90;
    this.analyser.maxDecibels = -10;
  }

  // Setup audio nodes
  private setupAudioNodes(): void {
    this.sourceNode.connect(this.analyser);
    this.analyser.connect(this.ctx.destination);
  }

  // Setup canvas styles
  private setupCanvasStyles(): void {
    this.canvasCtx.fillStyle = this.barColor;
    this.canvasCtx.shadowBlur = this.shadowBlur;
    this.canvasCtx.shadowColor = this.shadowColor;
  }

  // Bind events
  private bindEvents(): void {
    // Listen to play, pause, and ended events
    this.audio.addEventListener('play', this.onPlay.bind(this));
    this.audio.addEventListener('pause', this.onPause.bind(this));
    this.audio.addEventListener('ended', this.onEnded.bind(this));
  }

  private onPlay(): void {
    this.isPlaying = true;
    if (this.ctx.state === 'suspended') {
      this.ctx.resume().then(() => {
        this.renderFrame();
      });
    } else {
      this.renderFrame();
    }
  }

  private onPause(): void {
    this.isPlaying = false;
    this.stopRendering();
  }

  private onEnded(): void {
    this.isPlaying = false;
    this.stopRendering();
    if (this.onComplete) {
      this.onComplete();
    }
  }

  // Stop rendering the visualization
  private stopRendering(): void {
    if (this.animationFrameId) {
      cancelAnimationFrame(this.animationFrameId);
      this.animationFrameId = 0;
    }
  }

  // Render the visualization frame
  private renderFrame(): void {
    if (!this.isPlaying) return;

    this.animationFrameId = requestAnimationFrame(this.renderFrame.bind(this));
    this.analyser.getByteFrequencyData(this.frequencyData);

    this.canvasCtx.clearRect(0, 0, this.canvas.width, this.canvas.height);

    this.renderByStyleType();
  }

  // Render the visualization based on the style type
  public renderByStyleType(): void {
    const methodName = TYPE[this.style];
    const method = this[methodName] as () => void;
    if (typeof method === 'function') {
      method.call(this);
    } else {
      console.error(`Method ${methodName} is not a function`);
    }
  }

  // Visualization method for the 'radial' style
  public renderRadial(): void {
    const cx = this.canvas.width / 2;
    const cy = this.canvas.height / 2;
    const padding =
      this.barHeight + this.shadowBlur + this.barSpacing + this.barWidth;
    const radius =
      Math.min(this.canvas.width, this.canvas.height) / 2 - padding;

    // Voice frequency range in Hz
    const voiceMinHz = 85;
    const voiceMaxHz = 4000;

    // Total number of frequency bins
    const totalBins = this.frequencyData.length;

    // Nyquist frequency
    const nyquist = this.ctx.sampleRate / 2;

    // Indices corresponding to voice frequencies
    const voiceMinIndex = Math.floor((voiceMinHz / nyquist) * totalBins);
    const voiceMaxIndex = Math.floor((voiceMaxHz / nyquist) * totalBins);

    // Number of bins in the voice frequency range
    const voiceBinCount = voiceMaxIndex - voiceMinIndex;

    // Angle between each bar
    const angleStep = (2 * Math.PI) / voiceBinCount;

    for (let i = 0; i < voiceBinCount; i++) {
      const index = voiceMinIndex + i;
      const amplitude = this.frequencyData[index];
      const angle = i * angleStep;
      const x = cx;
      const y = cy;
      const h = (amplitude / 255) * radius;

      this.canvasCtx.save();
      this.canvasCtx.translate(x, y);
      this.canvasCtx.rotate(angle);

      // Ensure that the bar does not extend beyond the radius
      const barLength = Math.min(h, radius);
      const yPos = radius - barLength;

      this.canvasCtx.fillRect(0, yPos, this.barWidth, barLength);
      this.canvasCtx.restore();
    }
  }

  // Visualization method for the 'line' style
  public renderLine(): void {
    const canvasWidth = this.canvas.width;
    const canvasHeight = this.canvas.height;
    const barWidth = this.barWidth;
    const barSpacing = this.barSpacing;
    const barTotalWidth = barWidth + barSpacing;
    const barCount = Math.floor(canvasWidth / barTotalWidth);
    const freqJump = Math.floor(this.frequencyData.length / barCount);

    for (let i = 0; i < barCount; i++) {
      const index = i * freqJump;
      const amplitude = this.frequencyData[index];
      const barHeight = (amplitude / 255) * canvasHeight;
      const x = i * barTotalWidth;
      const y = canvasHeight - barHeight;

      this.canvasCtx.fillRect(x, y, barWidth, barHeight);
    }
  }
}
