import {create} from 'zustand';

interface AudioPlayerState {
  audio: HTMLAudioElement; // The HTMLAudioElement
  audioSrc?: string;
  setAudioSrc: (src: string) => void; // Function to set the audio source
  setAudio: (audio: HTMLAudioElement) => void;
  play: () => void; // Function to play the audio
  stop: () => void; // Function to stop the audio
  resetPlayer: () => void; // Function to reset the player
}

export const useAudioPlayerStore = create<AudioPlayerState>(set => {
  const audio = new Audio(); // Initialize HTMLAudioElement

  // Update the store whenever the audio is playing or paused
  const updatePlayingState = () => {
    set({audio}); // Set the audio element in the state (you can derive `isPlaying` from the audio element)
  };

  // Event listeners to manage playing state
  audio.addEventListener('play', updatePlayingState);
  audio.addEventListener('pause', updatePlayingState);
  audio.addEventListener('ended', () => {
    set({audio}); // Optionally reset state when audio ends
  });

  return {
    audio, // Return the audio element
    audioSrc: '',
    setAudioSrc: src => {
      set(() => ({audioSrc: src}));
    },

    setAudio: audio => set({audio}),

    play: () => {
      audio.play().catch(error => {
        console.error('Error playing audio:', error); // Handle potential playback errors
      });
    },

    stop: () => {
      audio.pause(); // Pause the audio
      audio.currentTime = 0; // Reset playback position to the start
    },

    resetPlayer: () => {
      audio.pause(); // Stop the audio if playing
      audio.src = ''; // Clear the audio source
      audio.currentTime = 0; // Reset playback position
      set({audio}); // Reset the audio element
    },
  };
});

export default useAudioPlayerStore;
