import React from 'react';
import styled from 'styled-components';

// Components
import {ReceivedMessage} from '../ReceivedMessage/ReceivedMessage';
import {SentMessage} from '../SentMessage/SentMessage';
import {ChatBotV3Conversation} from 'containers/chatbots/ChatBotV3Container';
import ChatBotDummyContainer from './ChatbotDummyContainer';

// Types
import {Exercise} from 'types/chapters/chapters';
import {useChatBotCommunicationStore} from 'store/ChatBotCommunication';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

interface Props {
  conversation: ChatBotV3Conversation[];
  exercise: Exercise;
}

const MessageGroup = ({exercise, conversation}: Props) => {
  // Store
  const {textToSpeech} = useChatBotCommunicationStore();

  const renderMessages = () => {
    if (!textToSpeech)
      return conversation.map((item, index) => {
        if (item.role === 'assistant') {
          return (
            <ReceivedMessage
              message={item.content}
              hideDate
              key={`${item.content}_received_${index}`}
              htmlContent
            />
          );
        }

        if (item.role === 'user') {
          return (
            <SentMessage
              message={item.content}
              key={`${item.content}_sent_${index}`}
              hideDate
            />
          );
        }

        return null;
      });
    return null;
  };

  return (
    <Container>
      {(conversation.length === 0 || exercise.isDescriptionSticky) && (
        <ChatBotDummyContainer
          title={exercise.subtitle}
          description={exercise.description}
        />
      )}
      {renderMessages()}
    </Container>
  );
};

export default MessageGroup;
