import React from 'react';
import styled from 'styled-components';

import {useParams} from 'react-router-dom';

// Components
import {BasePage} from 'components/common';
import {CoursePreviewFooter} from 'components/courses/common/CourseDetails';
import {CourseSidebar} from 'components/courses/common/CourseDetails';
import {ManagerTopCourseDetails} from 'components/courses/common/CourseDetails/ManagerTopCourseDetails';

// Hooks
import {useGetCourseOverview} from 'api/tanstack-hooks/courses';
import {useGetCourseChaptersOverview} from 'api/tanstack-hooks/courses/useGetCourseChaptersOverview';

const CourseContainer = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: #f6f6f8;
  min-height: calc(100vh - 80px);
`;

const MainContentContainer = styled.div`
  margin: 20px auto;
  width: 60%;
`;

export const ManagerCourseDetails: React.FC = () => {
  const {courseId} = useParams();

  const {data} = useGetCourseOverview(Number(courseId));
  const {data: chaptersData} = useGetCourseChaptersOverview(Number(courseId));

  return (
    <BasePage>
      {data && chaptersData && (
        <CourseContainer>
          <MainContentContainer>
            <ManagerTopCourseDetails
              chapters={chaptersData.data}
              assignedCourse={data.data}
            />
            <CoursePreviewFooter course={data.data} />
          </MainContentContainer>
          <CourseSidebar
            overviewUrl=""
            chapters={chaptersData.data}
            allowSelection={false}
          />
        </CourseContainer>
      )}
    </BasePage>
  );
};
