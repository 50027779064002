import {
  Space,
  Avatar,
  Breadcrumbs,
  Anchor,
  Select,
  Button,
  ActionIcon,
} from '@mantine/core';
import {BasePage} from 'components/common';
import {MainContainer} from 'components/common/Containers';
import {Color} from 'enums/common';
import React, {useState} from 'react';
import {useLocation, useParams} from 'react-router-dom';
import styled from 'styled-components';

import {ArrowRight} from 'tabler-icons-react';

import FeedbackCard from 'components/common/FeedbackCard/FeedbackCard';
import {useUserOverview} from 'api/tanstack-hooks/users/useUserOverview';
import {useCurrentWeeksOfYear} from 'utils/common/datesUtil';
import {capitalizeFirstLetter} from 'utils/common';
import {ArrowLeftIcon} from '../dashboard/helper-components/icons/ArrowLeftIcon';
import {ArrowRightIcon} from '../dashboard/helper-components/icons/ArrowRightIcon';
import {UserMetrics} from './_UserMetrics';
import {
  PointsChart,
  PointsChartProps,
} from '../../components/dashboard/common/PointsChart';
import {AllSummaryCourses} from '../../components/courses/user/SummaryCourse/AllSummaryCourses';
import {useTranslation} from 'react-i18next';
import i18n from 'i18n';
import {CustomTrophiesBadgeComponent} from './components/CustomTrophiesBadgeComponent/CustomTrophiesBadgeComponent';

const StyledWelcome = styled.div`
  background-color: ${Color.DARK};
  height: 230px;
`;

const User = styled.div`
  width: 100%;
  display: flex;
  padding-bottom: 16px;
`;

const Level = styled.div`
  height: 20px;
  padding: 0 12px;
  border-radius: 25px;
  width: max-content;
  background-color: #5c4d8a;
  color: white;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
`;

const DetailsGroup = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-left: 14px;
`;

const Name = styled.div`
  color: #ff7575;
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  line-height: 40px;
  letter-spacing: 0.15px;
`;

const Details = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const SectionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
  padding-bottom: 16px;
`;

const ChartWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  width: 100%;
  gap: 0;
  padding: 24px;
  border-radius: 4px;
`;

const ChartHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  z-index: 1;

  input {
    color: ${Color.DARK};
  }
`;

const ChartTitle = styled.div`
  color: ${Color.DARK};
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
`;

const StyledBreadcrumbs = styled(Breadcrumbs)`
  padding-bottom: 10px;
  color: ${Color.DARK};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;

  & div {
    margin: 6px;
    display: flex;
    align-items: center;
    vertical-align: middle;
  }
`;

const BreadcrumbsStyle = {
  breadcrumb: {
    fontFamily: 'Montserrat',
    fontSize: '12px',
    lineHeight: '16px',
    letterSpacing: '0.15px',
    fontWeight: 500,
    color: Color.WHITE,
  },
  separator: {color: Color.WHITE, fontSize: '12px', fontWeight: 500},
};

const Department = styled.div`
  color: white;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0.15px;
  display: flex;
  gap: 10px;
`;

const ChartFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;

  color: ${Color.DARK};

  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
`;

interface BulletPointProps {
  width?: string;
  height?: string;
  backgroundColor?: string;
}

const BulletPoint = styled.span<BulletPointProps>`
  display: inline-block;
  width: ${({width = '10px'}) => width};
  height: ${({height = '10px'}) => height};
  background-color: ${({backgroundColor = 'black'}) => backgroundColor};
  border-radius: 50%;
  margin-right: 4px;
`;

const TitleAndWeekSelectionWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const UserProfile = () => {
  // Initialize state with the first week's values

  const {getCurrentWeeksOfYear} = useCurrentWeeksOfYear();
  const weeks = getCurrentWeeksOfYear();
  const initialWeek =
    weeks.length > 0 ? weeks[weeks.length - 1].value : {from: '', to: ''};
  const [{from, to}, setSelectedWeek] = useState(initialWeek);

  const {
    state: {departmentId},
  } = useLocation();
  const {userId} = useParams();
  const {t} = useTranslation('UserProfile');

  const handleWeekChange = (direction: number) => {
    const currentWeekIndex = weeks.findIndex(
      week => week.value.from === from && week.value.to === to
    );

    if (currentWeekIndex === -1) return;

    const newWeekIndex = currentWeekIndex + direction;

    if (newWeekIndex >= 0 && newWeekIndex < weeks.length) {
      const newWeek = weeks[newWeekIndex].value;
      setSelectedWeek(newWeek); // Update the selected week range
    }
  };

  const {data: userProfile} = useUserOverview({
    departmentId: Number(departmentId),
    userId: Number(userId),
    from,
    to,
  });

  const {
    assignedCourses,
    completedCourses,
    points,
    departmentName,
    name,
    avatar,
    level,
    pointsPerDay,
    awards,
    trophyPoints,
    weeklyPoints,
    hasWeeklyTrophy,
  } = userProfile?.data || {};

  const maxVoluntaryPoints = Math.max(
    ...Object.values(pointsPerDay ?? {}).map(day => day.voluntaryPoints)
  );

  const leftScalePoints = maxVoluntaryPoints > 50 ? maxVoluntaryPoints : 50;

  const pointsDetails: PointsChartProps[] = Object.entries(
    userProfile?.data?.pointsPerDay || {}
  )?.map(([day, {trophyPoints, voluntaryPoints}]) => ({
    day: capitalizeFirstLetter(day.substring(0, 3)),
    trophyPoints: trophyPoints,
    voluntaryPoints: voluntaryPoints,
  }));

  const breadCrumbItems = [
    {title: 'Dashboard', href: '/'},
    {
      title: `${i18n.language === 'en' ? `${name}'s` : name} ${t('overview')}`,
    },
  ].map((item, index) => (
    <Anchor key={index} href={item?.href}>
      {item.title}
    </Anchor>
  ));

  return (
    <BasePage>
      <StyledWelcome />
      <MainContainer style={{marginTop: '-130px'}}>
        <StyledBreadcrumbs
          separator={<ArrowRight size={16} strokeWidth={1.5} />}
          styles={BreadcrumbsStyle}
        >
          {breadCrumbItems}
        </StyledBreadcrumbs>
        <User>
          <Avatar
            style={{border: '2px solid white'}}
            radius={100}
            src={avatar ?? '/images/neutral-avatar.png'}
            size={155}
          />
          <DetailsGroup>
            <Name>{name}</Name>

            <Details>
              <Level> {level}</Level>
              <Department>
                <span>&#8226;</span>
                {departmentName}
              </Department>
            </Details>
          </DetailsGroup>
        </User>
        <SectionWrapper>
          <UserMetrics
            awards={awards}
            assignedCoursesLength={assignedCourses?.length || 0}
            completedCourses={completedCourses}
            points={points}
            trophyPoints={trophyPoints}
            weeklyPoints={weeklyPoints}
          />
          <ChartWrapper>
            <TitleAndWeekSelectionWrapper>
              <ChartHeader>
                <ChartTitle>{`${t('UserProfile:chart.title')}`}</ChartTitle>
              </ChartHeader>
              <Button.Group style={{display: 'flex', gap: 2}}>
                <ActionIcon
                  style={{
                    zIndex: 1,
                  }}
                  onClick={() => handleWeekChange(-1)}
                  className="left"
                  size={37}
                  variant="default"
                >
                  <ArrowLeftIcon />
                </ActionIcon>
                <Select
                  style={{
                    zIndex: 999,
                  }}
                  placeholder="Choose a week"
                  data={weeks.map(week => ({
                    value: JSON.stringify(week.value),
                    label: week.label,
                  }))}
                  value={JSON.stringify({from, to})}
                  onChange={value => setSelectedWeek(JSON.parse(String(value)))}
                  dropdownPosition="bottom"
                  withinPortal
                  styles={{
                    wrapper: {
                      alignSelf: 'flex-end',
                    },
                    item: {
                      '&[data-selected], &[data-hovered]': {
                        backgroundColor: Color.GRAY_50,
                        color: Color.DARK,
                      },
                      '&[data-selected]': {
                        '&:hover': {
                          backgroundColor: Color.GRAY_50,
                        },
                      },
                    },
                  }}
                />
                <ActionIcon
                  style={{
                    zIndex: 1,
                  }}
                  onClick={() => handleWeekChange(1)}
                  className="right"
                  size={37}
                  variant="default"
                >
                  <ArrowRightIcon />
                </ActionIcon>
              </Button.Group>
            </TitleAndWeekSelectionWrapper>
            <div
              style={{
                width: '100%',
                marginRight: '-90px',
                marginTop: '-25px',
              }}
            >
              <PointsChart
                maxPointsValue={leftScalePoints}
                pointsDetails={pointsDetails}
              />
            </div>
            <ChartFooter>
              <div style={{display: 'flex', gap: '1rem'}}>
                <div>
                  <BulletPoint backgroundColor={Color.GREEN_050} />{' '}
                  <span>{`${t('UserProfile:chart.trophyPoints')}`}</span>
                </div>
                <div>
                  <BulletPoint backgroundColor={Color.PURPLE_TXT} />{' '}
                  <span> {`${t('UserProfile:chart.voluntaryPoints')}`}</span>
                </div>
              </div>
              <div>
                {hasWeeklyTrophy && (
                  <CustomTrophiesBadgeComponent
                    displayText={`1 ${t('Dashboard:charts.numberOfTrophies')}`}
                  />
                )}
              </div>
            </ChartFooter>
          </ChartWrapper>
        </SectionWrapper>

        <FeedbackCard
          imageSrc={'/images/feedback.png'}
          backgroundColor="LIGHT_GREEN"
          buttonText={t('feedback.goToFeedback')}
          title={t('feedback.title', {name: name || ''})}
          to={`/dashboard/feedback/userId/${userId}`}
        />

        <AllSummaryCourses
          courses={userProfile?.data.assignedCourses || []}
          topics={userProfile?.data.topics || []}
        />
        <Space h={50} />
      </MainContainer>
    </BasePage>
  );
};
