import {Box, Card, Text} from '@mantine/core';
import React from 'react';
import {useTranslation} from 'react-i18next';
import styled from 'styled-components';
import {Trophy} from '../../../../components/icons/Trophy/Trophy';
import {Color} from '../../../../enums/common';
import {UserTrophiesComponentInterface} from './interfaces/UserTrophiesComponentInterface';

const Title = styled(Text)`
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  color: ${Color.DARK};
`;

const Value = styled(Text)`
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: ${Color.DARK};
`;

const MainWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${Color.DARK};
`;

const ChildWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  align-items: flex-start;
  gap: 1.5rem;
  margin-bottom: 1rem;
  color: ${Color.DARK};
`;

export const UserTrophiesComponent = ({
  totalNumberOfTrophies,
  backgroundColor = '',
}: UserTrophiesComponentInterface) => {
  const {t} = useTranslation('Dashboard');

  return (
    <Card
      withBorder
      sx={{
        width: '100%',
        display: 'flex',
        maxHeight: '109px',
        flexDirection: 'column',
        justifyContent: 'center',
        ...(backgroundColor && {backgroundColor}),
      }}
    >
      <MainWrapper>
        <ChildWrapper>
          <Title>{t('metricCards.totalTrophies')}</Title>
          <Value>{totalNumberOfTrophies}</Value>
        </ChildWrapper>
        <Box style={{position: 'relative', top: 15}}>
          <Trophy height={95} width={100} />
        </Box>
      </MainWrapper>
    </Card>
  );
};
