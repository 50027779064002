// AudioPlayer.tsx

import React, {useEffect, useRef} from 'react';
import styled from 'styled-components';
import {Visualizer, VisualizerConfig} from './audioVisualizer'; // Adjust the import path
import {useAudioPlayerStore} from 'store/AudioPlayerStore'; // Adjust import path

const CenteredWrapper = styled.div`
  display: flex;
  justify-content: center; /* Centers children horizontally */
  align-items: center; /* Centers children vertically (optional) */
  width: 100%; /* Optional: set the width of the wrapper */
  height: 100%; /* Optional: set the height of the wrapper */
`;

export const AudioPlayer: React.FC = () => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const visualizerRef = useRef<Visualizer | null>(null);

  const audioSrc = useAudioPlayerStore(state => state.audioSrc);

  useEffect(() => {
    if (audioSrc && canvasRef.current) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const audioElement = (document as any).voice;
      const canvasElement = canvasRef.current;

      // Instantiate the Visualizer
      const visualizerConfig: VisualizerConfig = {
        audioElement: audioElement,
        canvasElement: canvasElement,
        style: 'line',
        barWidth: 5,
        barHeight: 2,
        barSpacing: 10,
        barColor: '#349d8e',
        shadowBlur: 20,
        shadowColor: '#ffffff',
      };

      visualizerRef.current = new Visualizer(visualizerConfig);
    }

    // Clean up on unmount
    return () => {
      visualizerRef.current = null;
    };
  }, [audioSrc]);

  return (
    <CenteredWrapper>
      <canvas ref={canvasRef} width={200} height={25}></canvas>
    </CenteredWrapper>
  );
};

export default AudioPlayer;
