import React, {FC} from 'react';
import styled from 'styled-components';
import {MetricCard} from 'components/common/Cards/MetricCard';
import {useTranslation} from 'react-i18next';
import {UserTrophiesComponent} from './components/CustomUserTrophiesComponent/UserTrophiesComponent';
import {WeeklyPointsComponent} from './components/WeeklyPointsComponent/WeeklyPointsComponent';

interface UserMetricsProps {
  points?: number;
  completedCourses?: number;
  assignedCoursesLength?: number;
  awards?: number;
  weeklyPoints?: number;
  trophyPoints?: number;
}

const MetricWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;
  min-width: 300px;
  & > div {
    flex: 1;
  }
`;

export const UserMetrics: FC<UserMetricsProps> = ({
  assignedCoursesLength,
  completedCourses,
  awards,
  weeklyPoints,
  trophyPoints,
}) => {
  const {t} = useTranslation('Dashboard');

  return (
    <MetricWrapper>
      <UserTrophiesComponent totalNumberOfTrophies={awards} />
      <WeeklyPointsComponent
        weeklyPoints={weeklyPoints}
        trophyPoints={trophyPoints}
      />
      <MetricCard
        title={t('metricCards.coursesCompleted')}
        metricValue={`${completedCourses ?? 0}/${assignedCoursesLength ?? 0}`}
      />
    </MetricWrapper>
  );
};
