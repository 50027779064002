import React from 'react';
import styled from 'styled-components';
import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';

// Components
import {Anchor, Box, Breadcrumbs, Button} from '@mantine/core';
import {BreadcrumbsStyle} from 'styles';
import {DarkButtonStyle} from 'styles/buttons';
import {Text} from 'components/common';

// Types
import {Color} from 'enums/common';

// Hooks
import {useGetUserCourseDetails} from 'api/tanstack-hooks/courses';

// Types
import {AssignedCourse} from 'types/courses/courses';
import {Chapter} from 'types/chapters/chapters';
import {CoursePointsPreview} from '../../CourseDetails/CoursePointsPreview/CoursePointsPreview';

const StyledBreadcrumbs = styled(Breadcrumbs)`
  margin-bottom: 70px;
`;

const CoursePreviewHeaderContainer = styled.div`
  background-color: #e7e7f8;
  height: 300px;
  padding: 15px 20px 0 130px;
  display: flex;
  justify-content: space-between;
`;

export interface CoursePreviewHeaderProps {
  course: AssignedCourse;
  chapters: Chapter[];
  isLeadership: boolean;
  userCourseId: string;
}

export const CoursePreviewHeader: React.FC<CoursePreviewHeaderProps> = ({
  course,
  chapters,
  isLeadership,
  userCourseId,
}) => {
  // Hooks
  const {t} = useTranslation('Courses');
  const {data: courseDetails} = useGetUserCourseDetails(
    course.userCourseId || Number(userCourseId)
  );

  const {continueCoursePosition} = courseDetails?.data || {};

  const availablePoints = course?.points ?? 0;

  const startCourseUrl = `/course?pIndex=${
    continueCoursePosition?.pagePosition || 0
  }&cIndex=${continueCoursePosition?.chapterPosition || 0}&userCourseId=${
    course.userCourseId || userCourseId
  }&isLeadership=${isLeadership}`;

  const breadCrumbItems = [
    {title: t('anchor.home'), href: '/'},
    {
      title: t('anchor.courses'),
      href: '/courses',
    },
    {title: `${course.courseName}`},
  ].map((item, index) => (
    <Anchor key={index} href={item?.href}>
      {item.title}
    </Anchor>
  ));

  const renderPointsComponent = () => {
    return (
      availablePoints > 0 && (
        <CoursePointsPreview totalPoints={availablePoints} />
      )
    );
  };

  return (
    <CoursePreviewHeaderContainer>
      <Box>
        <StyledBreadcrumbs separator="→" styles={BreadcrumbsStyle}>
          {breadCrumbItems}
        </StyledBreadcrumbs>
        <Text fontSize={28} fontWeight={700} color={Color.DARK} mb={12}>
          {course.courseName}
        </Text>
        {chapters.length > 0 ? (
          <Button
            component={Link}
            to={startCourseUrl}
            style={DarkButtonStyle}
            size="md"
          >
            {t('courseDetails.getStarted')}
          </Button>
        ) : (
          <Text fontWeight={700} color={Color.LIGHT_BLUE} mt={12}>
            {t('courseNotConfiguredProperly')}
          </Text>
        )}
      </Box>
      {renderPointsComponent()}
    </CoursePreviewHeaderContainer>
  );
};
