import React, {useEffect, useMemo, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {
  Microphone,
  X,
  PhoneOff,
  ThumbUp,
  AlertCircle,
  ThumbDown,
} from 'tabler-icons-react';
import styled from 'styled-components';

// Store
import {useChatBotCommunicationStore} from 'store/ChatBotCommunication';

// Components
import {
  Alert,
  Anchor,
  Button,
  Group,
  Loader,
  Select,
  ThemeIcon,
  Switch,
} from '@mantine/core';
import {Text} from 'components/common';
import MessageGroup from './MessageGroup';
import {ChatbotV3Typing} from './ChatbotV3Typing';
import {useChatBotV3Store} from 'store/ChatBotV3store';

// Types
import {Exercise} from 'types/chapters/chapters';

// Utils
import {Color} from 'enums/common';
import {MsSpeechToText} from 'utils/common';
import useNetworkStatus from 'hooks/useNetworkStatus';
import {AudioPlayer} from 'containers/player';
import useAudioPlayerStore from 'store/AudioPlayerStore';

// Styled components
const Container = styled.div`
  box-shadow: 0 2px 4px 0 #4d3c8233;
`;

const ConversationContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  border: 1px solid #e6e6ea;
  box-shadow: 4px 4px 40px rgba(0, 0, 0, 0.05);
  width: 100%;
  height: 550px;
  padding: 0 32px 0 32px;
  overflow-y: scroll;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  position: sticky;
  top: 0;
  margin-bottom: 20px;
  border-bottom: 1px solid #f1f0f0;
  background-color: ${Color.WHITE};
  z-index: 1;
  padding: 20px 10px;

  & > div {
    flex: 1;
    max-width: 33%;
    box-sizing: border-box;
  }

  & > div:last-child {
    margin-left: auto; /* Aligns the last child to the right */
    text-align: right;
  }
`;

export const Protip = styled.div`
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${Color.PURPLE_400};
  height: 32px;
  padding: 0 35px;
`;

const Footer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  border: 1px solid #e6e6ea;
  background-color: ${Color.GRAY_500};
  gap: 5px;
  padding: 0 10px;
  position: relative;
`;

const Label = styled.div`
  display: flex;
  justify-content: center;
  font-size: 14;
  font-weight: 400;
  color: ${Color.DARK};
  width: max-content;
`;

const SwitchContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const CoversationFeedback = styled.div`
  min-width: 8.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 30px;
  gap: 10;
  position: absolute;
  right: 15px;
`;

const LoaderWrapper = styled.div`
  padding: 0 30px;
  position: absolute;
  right: 1px;
`;

interface Props {
  exercise: Exercise;

  maxTries: number;
  onUserConversationFeedback: ({value}: {value: boolean}) => void;
}

const ChatBotV3 = ({exercise, maxTries, onUserConversationFeedback}: Props) => {
  const {t} = useTranslation('Practices');
  // Refereces
  const bottomRef = useRef<HTMLDivElement>(null);

  // Store
  const {responseTime, setResponseTime, textToSpeech, toggleTextToSpeech} =
    useChatBotCommunicationStore();

  const {
    setPage,
    setTextMessage,
    setEndConversation,
    conversation,
    sendingMessage,
    endConversation,
    tries,
    userFeedback,
  } = useChatBotV3Store();
  const resetPlayer = useAudioPlayerStore(state => state.resetPlayer);

  // State
  const [isRecording, setIsRecording] = useState(false);
  const [showAlert, setShowAlert] = useState(false);

  // Hooks
  const {isOnline} = useNetworkStatus();

  // Handlers
  const recordVoice = async () => {
    resetPlayer();

    setTimeout(() => {
      bottomRef.current?.scrollIntoView({behavior: 'smooth'});
    }, 100);

    if (!isRecording) {
      setIsRecording(true);

      try {
        const text = await msSpeechToText.startRecognition();
        setTextMessage(text || '');
      } catch (error) {
        console.error('Error during recognition:', error);
      }

      setIsRecording(false);

      setTimeout(() => {
        bottomRef.current?.scrollIntoView({behavior: 'smooth'});
      }, 100);
    }
  };

  const stopRecording = () => {
    if (isRecording) {
      msSpeechToText.stopRecognition();
      setIsRecording(false);
      console.log('Recognition stopped manually.');
    }
  };

  // Memo
  const msSpeechToText = useMemo(() => {
    const id = exercise && exercise.language ? exercise.language.id : 0;
    return new MsSpeechToText(id);
  }, [exercise]);

  // Effects
  useEffect(() => {
    const timer = setTimeout(() => {
      bottomRef.current?.scrollIntoView({behavior: 'smooth'});
    }, 100);

    return () => clearTimeout(timer);
  }, [conversation]);

  useEffect(() => {
    msSpeechToText.setDelay(responseTime);
  }, [responseTime, msSpeechToText]);

  useEffect(() => {
    let timer: NodeJS.Timeout | undefined;

    if (!isOnline) {
      setShowAlert(true);
      timer = setTimeout(() => {
        setShowAlert(false);
      }, 11000);
    } else if (showAlert) {
      timer = setTimeout(() => {
        setShowAlert(false);
      }, 10000);
    }

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOnline]);

  return (
    <Container>
      <ConversationContainer>
        <Header>
          <Text fontSize={18} fontWeight={700} color={Color.DARK}>
            {exercise.title}
          </Text>
          {showAlert && (
            <Alert
              icon={<AlertCircle size={16} />}
              title={t('voicebot.connectionLostAlert')}
              color="red"
              withCloseButton
              closeButtonLabel="Close alert"
              onClose={() => setShowAlert(false)}
            >
              <Text>{t('voicebot.restartExercise')}</Text>
              <Anchor
                onClick={() => window.location.reload()}
                style={{marginTop: 10, display: 'inline-block'}}
              >
                Restart
              </Anchor>
            </Alert>
          )}

          {textToSpeech && <AudioPlayer />}
          <Text fontSize={12} fontWeight={400} color={Color.DARK}>
            #{tries}
          </Text>
        </Header>

        <MessageGroup conversation={conversation} exercise={exercise} />
        {isRecording && !endConversation && <ChatbotV3Typing sender />}
        {sendingMessage && !endConversation && <ChatbotV3Typing />}
        <div ref={bottomRef} style={{paddingBottom: '20px'}} />
      </ConversationContainer>
      {conversation.length === 0 && (
        <Protip>
          <Group spacing={11}>
            <Microphone />
            <Text fontSize={12} fontWeight={500} color={Color.DARK}>
              {t('voicebot.start_chatting_with_voicebot_3')}
            </Text>
          </Group>
        </Protip>
      )}
      {endConversation && (
        <Protip>
          <Group spacing={11}>
            <ThumbUp />
            <Text fontSize={12} fontWeight={500} color={Color.DARK}>
              {conversation.length > 0 &&
                endConversation &&
                tries < maxTries &&
                userFeedback !== null &&
                t('voicebot.conversationFeedback')}
              {conversation.length > 0 &&
                endConversation &&
                tries === maxTries &&
                userFeedback !== null &&
                t('voicebot.exerciseEnded3Tries')}
              {userFeedback === null &&
                endConversation &&
                t('voicebot.conversationFeedback')}
            </Text>
          </Group>
        </Protip>
      )}

      <Footer>
        <Group
          style={{
            position: 'absolute',
            left: 15,
            display: 'flex',
            flexFlow: 'row',
          }}
        >
          <Select
            label={<Label>{t('voicebot.wait')}</Label>}
            data={[
              {value: '500', label: '0.5s'},
              {value: '1000', label: '1s'},
              {value: '2000', label: '2s'},
              {value: '3000', label: '3s'},
              {value: '4000', label: '4s'},
              {value: '5000', label: '5s'},
            ]}
            defaultValue={responseTime}
            style={{width: 80}}
            disabled={isRecording || sendingMessage || endConversation}
            onChange={value => setResponseTime(value || '')}
          />
          <SwitchContainer>
            <Label>{t('voicebot.voice')}</Label>
            <Switch
              size="lg"
              color="cyan"
              labelPosition="right"
              checked={textToSpeech}
              onChange={() => toggleTextToSpeech()}
              onLabel="ON"
              offLabel="OFF"
            />
          </SwitchContainer>
        </Group>
        <ThemeIcon
          onClick={recordVoice}
          size={56}
          radius="xl"
          color={
            endConversation
              ? Color.GRAY_250
              : isRecording
              ? Color.RED_800
              : Color.GREEN_050
          }
          style={{
            cursor: endConversation ? 'not-allowed' : 'pointer',
          }}
        >
          {isRecording ? (
            <X
              width={40}
              height={40}
              onClick={stopRecording}
              style={{
                cursor: 'pointer',
              }}
            />
          ) : (
            <Microphone
              style={{
                cursor: endConversation ? 'not-allowed' : 'pointer',
              }}
            />
          )}
        </ThemeIcon>
        {conversation.length > 0 && !endConversation && (
          <Button
            onClick={() => setEndConversation(true)}
            style={{
              fontFamily: 'Montserrat',
              fontSize: 14,
              fontWeight: 700,
              color: Color.WHITE,
              backgroundColor: '#f90000',
              borderRadius: 0,
              position: 'absolute',
              right: 15,
              opacity: isRecording || sendingMessage ? '50%' : '100%',
            }}
            leftIcon={<PhoneOff />}
            disabled={isRecording || sendingMessage}
          >
            {t('voicebot.endConversation')}
          </Button>
        )}

        {userFeedback === null && endConversation && (
          <CoversationFeedback>
            <span>
              <ThumbUp
                onClick={() => onUserConversationFeedback({value: true})}
                style={{cursor: 'pointer'}}
                size={30}
                color={Color.MID_GREEN}
              />
            </span>
            <span>
              <ThumbDown
                onClick={() => onUserConversationFeedback({value: false})}
                style={{cursor: 'pointer'}}
                size={30}
                color={Color.DARK_GREEN}
              />
            </span>
          </CoversationFeedback>
        )}

        {conversation.length > 0 &&
          endConversation &&
          tries < maxTries &&
          userFeedback !== null && (
            <LoaderWrapper>
              <Loader
                variant="oval"
                color={Color.DARK}
                size="xl"
                height={30}
                style={{alignSelf: 'center'}}
              />
            </LoaderWrapper>
          )}

        {conversation.length > 0 &&
          endConversation &&
          tries === maxTries &&
          userFeedback !== null && (
            <Button
              onClick={() => setPage(4)}
              style={{
                fontFamily: 'Montserrat',
                fontSize: 14,
                fontWeight: 700,
                color: Color.WHITE,
                backgroundColor: Color.DARK,
                borderRadius: 0,
                position: 'absolute',
                right: 15,
              }}
              leftIcon={<ThumbUp />}
            >
              {t('voicebot.analyzeConversation')}
            </Button>
          )}
      </Footer>
    </Container>
  );
};

export default ChatBotV3;
