import React, {Fragment, useContext, useState} from 'react';
import {Link} from 'react-router-dom';
import styled from 'styled-components';
import {useTranslation} from 'react-i18next';
import {Card, Image, Checkbox, Space, Text, Alert, Loader} from '@mantine/core';
import {UserContext} from '../../contexes';
import {Color} from '../../enums/common';
import {Role} from '../../enums/auth/Role';
import {UserProfileInfo} from '../../types/users/users';
import i18n from '../../i18n';
import {useMutation, useQueryClient} from '@tanstack/react-query';
import {updateUserProfile} from 'api/user/user';
import {AlertCircle, Check} from 'tabler-icons-react';
import {showNotification} from '@mantine/notifications';
import {AxiosError} from 'axios';

const StyledNames = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  padding-bottom: 5px;
`;

const StyledBody = styled.div`
  display: grid;
  grid-template-columns: auto auto auto;
  gap: 250px;
`;

const StyledHeader = styled.div`
  display: flex;
  flex-direction: row;
  padding-bottom: 8px;
`;

const StyledInformation = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15px;
`;

const BannerTitle = styled(Text)`
  font-size: 16px;
  font-weight: 700;
  font-family: 'Montserrat', sans-serif;
  color: ${Color.DARK};
`;

const StyledStaticText = styled.div`
  font-size: 14px;
  font-weight: 700;
  font-family: 'Montserrat', sans-serif;
  color: ${Color.DARK};
  padding-top: 21.5px;
  width: 120px;
`;

const StyledDynamicText = styled.div`
  font-size: 16px;
  font-weight: 500;
  font-family: 'Montserrat', sans-serif;
  color: ${Color.DARK};
  padding-top: 20px;
  width: 400px;
`;

const StyledStaticProfileText = styled.div`
  font-size: 14px;
  font-weight: 700;
  font-family: 'Montserrat', sans-serif;
  color: ${Color.DARK};
  padding-top: 21.5px;
  padding-bottom: 20px;
  width: 120px;
`;

const StyledProfileText = styled.div`
  font-size: 16px;
  font-weight: 500;
  font-family: 'Montserrat', sans-serif;
  color: ${Color.DARK};
  width: 400px;
  padding-bottom: 20px;
`;

const StyledStaticTitle = styled.div`
  font-size: 14px;
  font-weight: 700;
  font-family: 'Montserrat', sans-serif;
  color: ${Color.DARK};
`;

const StyledDynamicTitle = styled.div`
  font-size: 16px;
  font-weight: 500;
  font-family: 'Montserrat', sans-serif;
  color: ${Color.DARK};
`;

const PasswordDiv = styled.div`
  display: flex;
  flex-direction: column;
  height: 42px;
`;

const LanguageDiv = styled.div`
  display: flex;
  margin-top: 12px;
  gap: 16em;
`;

const StyledEditText = styled.div`
  font-size: 14px;
  font-weight: 400;
  font-family: 'Montserrat', sans-serif;
  color: ${Color.DARK};
  padding-top: 20px;
  cursor: pointer;
`;

const BannerText = styled(Text)`
  font-size: 14px;
  font-weight: 500;
  font-family: 'Montserrat', sans-serif;
  color: ${Color.DARK};
`;

const StyledCard = () => {
  return {
    marginLeft: '8.6em',
    width: '842px',
    padding: '2em 3em 1em 3em',
  };
};

const StyledInfoCard = () => {
  return {
    marginLeft: '8.6em',
    width: '842px',
    height: '21em',
    padding: '2em 3em 0 3em',
  };
};

const StyledP = styled.p`
  margin-bottom: -20px;
`;

const ChangePasswordText = () => {
  return {
    root: {
      fontSize: 14,
      fontFamily: 'Montserrat',
      backgroundColor: 'transparent',
      color: Color.GREEN_050,
      fontWeight: 700,
      marginTop: '-6px',

      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
  };
};

export interface MyProfileProps {
  userprofile: UserProfileInfo;
  setLanguageModalOpen: (isModalOpen: boolean) => void;
}
export const MyProfile: React.FC<MyProfileProps> = ({
  userprofile,
  setLanguageModalOpen,
}) => {
  const {t} = useTranslation('MyProfile');
  const [userContext] = useContext(UserContext);

  const [allowOverviewToTeamMembers, setAllowOverviewToTeamMembers] = useState(
    userprofile.allowOverviewToTeamMembers
  );

  const queryClient = useQueryClient();

  const {mutate, isLoading, error} = useMutation({
    mutationFn: () =>
      updateUserProfile(userprofile.id, {
        ...userprofile,
        mobile: userprofile.mobile ?? '',
        expertLevelId: userprofile.expertLevel?.id,
        genderId: userprofile.gender.id,
        languageId: userprofile.language?.id ?? 0,
        avatar: userprofile.imageId ?? '',
        allowOverviewToTeamMembers: !allowOverviewToTeamMembers,
      }),
    onSuccess: () => {
      setAllowOverviewToTeamMembers(prev => !prev);
      queryClient.invalidateQueries(['users']);
      showNotification({
        message: !allowOverviewToTeamMembers
          ? `${t('hiddenInformation')}`
          : `${t('visibleInformation')}`,
        icon: <Check size={16} />,
      });
    },
  });

  const _error = error as AxiosError;

  if (isLoading) {
    return (
      <div
        style={{
          width: '60vw',
          height: '80vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Loader />
      </div>
    );
  }

  return (
    <>
      <Card
        style={StyledCard()}
        withBorder
        shadow="sm"
        p="lg"
        radius="xs"
        mt="xl"
      >
        <Fragment>
          {_error?.message && (
            <Alert icon={<AlertCircle size={16} />} color="red">
              {_error.message}
            </Alert>
          )}

          <Card.Section withBorder inheritPadding py="xs">
            <StyledHeader>
              <StyledNames>
                <StyledStaticTitle>{t('name')}</StyledStaticTitle>
              </StyledNames>
              <StyledNames>
                <StyledDynamicTitle>
                  {userprofile.fullName ?? t('noNameProvided')}
                </StyledDynamicTitle>
              </StyledNames>
            </StyledHeader>
          </Card.Section>
          <StyledBody>
            <StyledNames>
              <StyledStaticText>{t('nickName')}</StyledStaticText>
              {userContext.authority.includes(Role.MANAGER) && (
                <>
                  <StyledStaticText>{t('company')}</StyledStaticText>
                  <StyledStaticProfileText>
                    {t('teams')}
                  </StyledStaticProfileText>
                </>
              )}
              <StyledStaticText>{t('email')}</StyledStaticText>
              <StyledStaticText>{t('password')}</StyledStaticText>
              <Space h={15} />
              <StyledStaticText>{t('language')}</StyledStaticText>

              {userContext.authority.includes(Role.MEMBER) && (
                <StyledStaticText>{t('disableInformation')}</StyledStaticText>
              )}
            </StyledNames>
            <StyledNames>
              <StyledDynamicText>{userprofile.nickName}</StyledDynamicText>
              {userContext.authority.includes(Role.MANAGER) && (
                <>
                  <StyledDynamicText>
                    {userprofile.organization.name}
                  </StyledDynamicText>
                  <StyledProfileText>
                    {userprofile.departments.map(profile => {
                      return (
                        <StyledP key={profile.departmentId}>
                          {profile.name}
                        </StyledP>
                      );
                    })}
                  </StyledProfileText>
                </>
              )}
              <StyledDynamicText>{userprofile.email}</StyledDynamicText>
              <PasswordDiv>
                <StyledDynamicText>{t('hiddenPassword')}</StyledDynamicText>
                <Text
                  component={Link}
                  to={`/change-password`}
                  style={ChangePasswordText().root}
                >
                  {t('changePass')}
                </Text>
              </PasswordDiv>
              <LanguageDiv>
                <StyledDynamicText>
                  {i18n.language === 'en' ? t('english') : t('norwegian')}
                </StyledDynamicText>
                <StyledEditText
                  onClick={() => {
                    setLanguageModalOpen(true);
                  }}
                >
                  {t('edit')}
                </StyledEditText>
              </LanguageDiv>
              {/* Privacy option */}
              {userContext.authority.includes(Role.MEMBER) && (
                <StyledDynamicText>
                  <Checkbox
                    styles={{input: {cursor: 'pointer'}}}
                    checked={!allowOverviewToTeamMembers}
                    onChange={() => mutate()}
                  />
                </StyledDynamicText>
              )}
            </StyledNames>
          </StyledBody>
        </Fragment>
      </Card>
      {userContext.authority.includes(Role.MANAGER) && (
        <Card
          style={StyledInfoCard()}
          withBorder
          shadow="sm"
          p="lg"
          radius="xs"
          mt="xl"
        >
          <Card.Section
            withBorder
            inheritPadding
            py="xs"
            style={{padding: '70px 60px', border: '3px solid #f4f4f4'}}
          >
            <StyledInformation>
              <Image width={40} height={40} src="../../images/flag.png" />
              <BannerTitle>{t('reachUs')}</BannerTitle>
              <BannerText>{t('emailContact')}</BannerText>
              <BannerText>{t('phoneContact')}</BannerText>
            </StyledInformation>
          </Card.Section>
        </Card>
      )}
      <Space h={30} />
    </>
  );
};
