import {Badge, Box} from '@mantine/core';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {Trophy} from '../../../../icons/Trophy/Trophy';
import {CoursePointsType} from './interfaces/CoursePointsType';

export const CoursePointsPreview = ({totalPoints}: CoursePointsType) => {
  const {t} = useTranslation('CoursePointsPreview');

  return (
    <Box>
      <Badge
        color="#f6f4fd"
        size="lg"
        styles={() => ({
          root: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: '#968db5',
          },
        })}
      >
        <Box
          sx={{
            display: 'inline-flex',
            alignItems: 'center',
            marginRight: 8,
            verticalAlign: 'middle',
          }}
        >
          <Trophy height={16} width={16} />
        </Box>
        {`${totalPoints} ${t('pointsForCompletion')}`}
      </Badge>
    </Box>
  );
};
